// React
import React from 'react';

// Material-ui
import {
  styled,
  useTheme,
  useMediaQuery,
  Box,
  Grid as MuiGrid,
  Typography,
  Divider as MuiDivider,
} from '@mui/material';

// Visuals
import CornerWavesVisual from '../assets/images/corner-waves-visual.svg';

const Root = styled(Box)(({ theme }) => ({
  '&.intro-section': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: 'calc(800px - 95px)',
    '& hr': {
      background: '#ffffff',
    },
    '&:before': {
      zIndex: -1,
      content: '""',
      width: '100%',
      height: 800,
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      background: `url(${CornerWavesVisual})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      transform: 'translateY(-95px)',
    },
  },
}));

const Grid = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
}));

const Content = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '75%',
  },
}));

const Actions = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0, 0),
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  width: '40%',
  height: theme.spacing(0.5),
  border: 'none',
  background: theme.palette.primary.light,
}));

const Visual = styled(Box)(({ theme }) => ({
  '& > img': {
    width: '100%',
    height: 'auto',
  },
  [theme.breakpoints.up('sm')]: {
    width: '80%',
  },
}));

interface IProps {
  uid: string;
  title: string;
  text: string[];
  visual: string;
  inverse?: boolean;
  action?: JSX.Element;
}

export const Section: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  let content = [
    <Grid key={`${props.uid}-content`} item xs={12} md={6}>
      <Content>
        <Typography gutterBottom variant="h4">
          {props.title}
        </Typography>
        {props.text.map((paragraph, index) => (
          <React.Fragment key={`${props.uid}-paragraph-${index}`}>
            <Typography>{paragraph}</Typography>
            {index !== props.text.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        <Actions>{props.action && props.action}</Actions>
      </Content>
    </Grid>,
    <Grid key={`${props.uid}-visual`} item xs={12} md={6}>
      <Visual>
        <img alt="" src={props.visual} />
      </Visual>
    </Grid>,
  ];

  props.inverse && !mobile && content.reverse();
  return (
    <Root className={props.uid === 'intro-section' ? 'intro-section' : ''}>
      <MuiGrid sx={{ mx: 'auto', maxWidth: '1920px' }} container>
        {content}
      </MuiGrid>
    </Root>
  );
};
