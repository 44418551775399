// React
import React from 'react';

// Material-ui
import { styled, Box, Chip as MuiChip, Typography } from '@mui/material';

// Types
import { IField } from '../../ts';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  padding: theme.spacing(3),
  '&.active-field': {
    position: 'relative',
    '& p': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  [theme.breakpoints.up('md')]: {
    '&.active-field': {
      padding: `${theme.spacing(3)} calc(${theme.spacing(6)} + 55px)`,
    },
  },
}));

const Icon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 0, 2),
  width: 128,
  height: 128,
  '& img': {
    width: '100%',
    height: 'auto',
  },
}));

const Chip = styled(MuiChip)(({ theme }) => ({}));

interface IProps extends Partial<IField> {
  active?: boolean;
}

export const ExperimentField: React.FC<IProps> = (props) => {
  return (
    <Root className={props.active ? 'active-field' : ''}>
      <Icon>
        <img alt="" src={props.icon} />
      </Icon>
      <Chip
        sx={{
          borderColor: props.color,
          color: props.color,
        }}
        variant="outlined"
        label={`${props.count} pokusa`}
      />
      <Typography gutterBottom variant="h4">
        {props.title}
      </Typography>
      <Typography
        color="textSecondary"
        variant="body2"
        align="center"
        maxWidth="sm"
      >
        {props.description}
      </Typography>
      {props.children}
    </Root>
  );
};
