// React
import React from 'react';

// Material-ui
import { styled, Box } from '@mui/material';

// Components
import { Socials } from '../../socials/Socials';
import { FooterLogo } from '../../footer/FooterLogo';

const Root = styled(Box)(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '25%',
  padding: theme.spacing(2),
}));

export const MenuFooter: React.FC = () => {
  return (
    <Root>
      <Socials />
    </Root>
  );
};
