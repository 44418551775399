// Node modules
import React from 'react';

// Material-ui
import {
  styled,
  Box,
  Dialog,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  useTheme,
  Divider,
} from '@mui/material';

const Root = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-end',
  },
  '& .MuiDialog-paper': {
    maxWidth: 'unset',
    margin: '0',   
    width: '100%', 
    padding: theme.spacing(2, 3, 3),
    borderTop: `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
    borderRadius: '0',
    borderTopLeftRadius: `${theme.spacing(3)}`,
    borderTopRightRadius: `${theme.spacing(3)}`,
  },
}));

const Decoration = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(2, 0),
}));

const Control = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const CookiesBanner: React.FC = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(() => {
    const cookiesAccepted = localStorage.getItem('cookies-accepted');

    if (cookiesAccepted && JSON.parse(cookiesAccepted)) {
      return false;
    }

    localStorage.setItem('cookies-accepted', 'false');
    return true;
  });

  const acceptCookies = () => {
    localStorage.setItem('cookies-accepted', 'true');
    setOpen(false);
  };

  return (
    <Root fullWidth open={open}>
      <Typography variant="h5" gutterBottom>
        Kolačići
      </Typography>
      <Typography>
        Mrežna stranica <strong>Istraživanjem do znanja</strong> koristi
        tehnički neophodne kolačiće koji su nužni za otvaranje i korištenje
        stranice u svrhu pružanja najboljeg korisničkog iskustva.
      </Typography>
      <Divider variant="middle" sx={{ my: 2 }} />
      <Control>
        <FormControlLabel
          label="Tehnički neophodni"
          control={<Checkbox disabled checked />}
          disabled
        />
        <Button variant="contained" onClick={acceptCookies}>
          Prihvati
        </Button>
      </Control>
    </Root>
  );
};
