// React
import React from 'react';

// Material-ui
import {
  styled,
  Box,
  Typography,
  Link,
  Divider as MuiDivider,
} from '@mui/material';

// Visuals
import CornerWaves from '../assets/images/corner-waves-visual.svg';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3),
  minHeight: '100vh',
  '&:before': {
    zIndex: -1,
    content: '""',
    width: '100%',
    height: 966,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    background: `url(${CornerWaves})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

const ProjectDetails = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  background: '#ffffff',
  borderRadius: theme.spacing(2),
  boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme.spacing(2.5, 0, 1.5),
  height: theme.spacing(0.5),
  width: '35%',
  background: theme.palette.primary.light,
  border: 'none',
  borderRadius: theme.spacing(1),
}));

const List = styled('ul')(({ theme }) => ({}));
const ListItem = styled('li')(({ theme }) => ({}));

export const AboutProject: React.FC = () => {
  return (
    <Root>
      <ProjectDetails maxWidth="md">
        <Typography sx={{ mb: 3 }} variant="h4">
          O projektu
        </Typography>
        <Box>
          <Typography gutterBottom variant="h6">
            Naziv projekta
          </Typography>
          <Typography>
            Istraživanjem do znanja (šifra poziva UP.04.2.1.10.0062)
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Partneri
          </Typography>
          <List>
            <ListItem>OMEP Hrvatska (nositelj projekta)</ListItem>
            <ListItem>Dječji vrtić Dobro Drvo Zagreb (partner)</ListItem>
            <ListItem>
              Fakultet elektrotehnike i računarstva Sveučilišta u Zagrebu
              (partner)
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Kratak opis projekta
          </Typography>
          <Typography>
            Projekt je usmjeren na jačanje kapaciteta civilnog društva za
            aktivno uključivanje djece vrtićkog uzrasta u popularizaciju STEM-a.
            Dodatno, u aktivnosti se kao krajnji korisnici uključuju mladi kao
            stručnjaci koji će u budućnosti raditi s djecom te roditelji djece
            vrtićkog uzrasta, a kako bi ih se osnažilo za poticanje djece u
            prihvaćanju STEM spoznaja i razvoju vještina koje su važne za STEM
            područje. Projekt je usmjeren na jačanje OCD-a koji kroz svoj rad
            populariziraju STEM područja na način da su u mogućnosti pružiti
            potreban izvaninstitucionalni sadržaj djeci i mladima.
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Ciljevi projekta
          </Typography>
          <Typography>
            Opći cilj projekta je jačanje kapaciteta OMEP Hrvatska za aktivno
            uključivanje djece, mladih i opće populacije u popularizaciju
            STEM-a.
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Očekivani rezultati projekta
          </Typography>
          <List>
            <ListItem>izrada kurikuluma za program trening za trenere</ListItem>
            <ListItem>
              uključivanje stručnjaka različitih profila u provedbu programa
              trening za trenere
            </ListItem>
            <ListItem>
              organizacija stručnih konferencija, znanstvenog sajma i
              znanstvenong pinkika
            </ListItem>
            <ListItem>
              izrada STEM kurikuluma za djecu vrtićkog uzrasta iz područja:
              ROBOTIKE, ZNANJA O SVEMIRU, KEMIJE, FIZIKE i MATEMATIKE
            </ListItem>
            <ListItem>
              provedba radionica za djecu vrtićkog uzrasta i roditelje
            </ListItem>
            <ListItem>
              nabavljanje specijalizirane opreme za provedbu aktivnosti i
              programa
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Ukupna vrijednost projekta i iznos koji sufinancira EU (u HRK)
          </Typography>
          <Typography>
            2.152.335,80 HRK (100% sufinanciranje iz EU fondova)
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Razdoblje provedbe projekta
          </Typography>
          <Typography>
            29. lipnja 2021. - 29. lipnja 2023. (24 mjeseca)
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Kontakt osoba za više informacija
          </Typography>
          <List>
            <ListItem>OMEP Hrvatska</ListItem>
            <ListItem>Adresa: Ivana Pl. Zajca 55, Čakovec</ListItem>
            <ListItem>
              E-mail:{' '}
              <Link href="mailto:avisnjic.jevtic@gmail.com">
                avisnjic.jevtic@gmail.com
              </Link>
            </ListItem>
          </List>
        </Box>
        <Divider />
        <Box>
          <Typography gutterBottom variant="h6">
            Poveznice
          </Typography>
          <List>
            <ListItem>
              <Link href="https://strukturnifondovi.hr/en/" target="_blank">
                Strukturni fondovi
              </Link>
            </ListItem>
            <ListItem>
              <Link href="http://www.esf.hr/" target="_blank">
                Europski socijalni fond
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href="https://strukturnifondovi.hr/en/eu-fondovi/esi-fondovi-2014-2020/op-ucinkoviti-ljudski-potencijali-2014-2020/"
                target="_blank"
              >
                Operativni program "Učinkoviti ljudski potencijali 2014.-2020."
              </Link>
            </ListItem>
          </List>
        </Box>
      </ProjectDetails>
    </Root>
  );
};
