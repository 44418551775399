// React
import React from 'react';

// Material-ui
import { styled, Box, Typography } from '@mui/material';

// Visual
import { TimelineDot } from '../../assets/icons/TimelineDot';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 0, 2),
  color: theme.palette.primary.dark,
  '& svg': {
    minWidth: 32,
    minHeight: 32,
    margin: theme.spacing(0, 2, 0, 0),
  },
}));

interface IProps {
  title: string;
}

export const ActivityTitle: React.FC<IProps> = (props) => {
  return (
    <Root>
      <TimelineDot />
      <Typography variant="h6">{props.title}</Typography>
    </Root>
  );
};
