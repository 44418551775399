// React
import React from 'react';

export const TimelineDot: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="14" stroke="#0A3A75" strokeWidth="4" />
      <circle cx="16" cy="16" r="8" fill="#0A3A75" />
    </svg>
  );
};
