// Node modules
import React from 'react';

// Material-ui
import { styled, Dialog, Button } from '@mui/material';

const ImageButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0),
  '& > img': {
    height: 'auto',
    width: '100%',
    borderRadius: theme.spacing(2),
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
  },
}));

interface IProps {
  image: string;
}

export const ExpendableImage: React.FC<IProps> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [image, setImage] = React.useState<string>('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <ImageButton onClick={handleOpen}>
        <img alt="" src={props.image} />
      </ImageButton>
      <Dialog onClose={handleClose} open={open}>
        <img alt="" src={props.image} />
      </Dialog>
    </React.Fragment>
  );
};
