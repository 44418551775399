// React
import React from 'react';

// React rotuer
import { Routes, Route } from 'react-router-dom';

// App
import { Homepage } from './app/Homepage';
import { Layout } from './app/Layout';
import { Activities } from './app/Activities';
import { Experiments } from './app/Experiments';
import { AboutProject } from './app/AboutProject';
import { NoMatch } from './app/NoMatch';

export const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Homepage />} />
        <Route path="aktivnosti" element={<Activities />} />
        <Route path="pokusi" element={<Experiments />} />
        <Route path="o-projektu" element={<AboutProject />} />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
