// React
import React from 'react';

// Material-ui
import { styled, Box, Typography } from '@mui/material';

// Visuals
import WavesVisual from '../../assets/images/waves-visual.svg';

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(6, 3),
  background: theme.palette.primary.light,
  margin: theme.spacing('117px', 0),
  '&:before': {
    content: '""',
    width: '100%',
    height: 117,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    background: `url(${WavesVisual})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transform: 'translateY(-99%)',
  },
  '&:after': {
    content: '""',
    width: '100%',
    height: 117,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    background: `url(${WavesVisual})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transform: 'scaleY(-1) translateY(-99%)',
  },
}));

export const HomepageRecentExperiments: React.FC = () => {
  return (
    <Root>
      <Typography
        sx={{ color: 'rgba(0, 0, 0, .6)' }}
        align="center"
        variant="h2"
      >
        Pokusi dolaze uskoro...
      </Typography>
    </Root>
  );
};
