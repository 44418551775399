// React
import React from 'react';

// Material-ui
import {
  styled,
  useTheme,
  Box,
  MobileStepper,
  Button as MuiButton,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  flexGrow: 1,
}));

const Stepper = styled(MobileStepper)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0),
  background: 'rgba(0, 0, 0, 0)',
}));

const Button = styled(MuiButton)(({ theme }) => ({}));

interface IProps {
  items: any[];
}

export const Carousel: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.items.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  console.log(props.items);

  return (
    <Root>
      {props.items[activeStep]}
      <Stepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            variant="contained"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
      />
    </Root>
  );
};
