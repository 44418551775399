// React
import React from 'react';

// Material-ui
import { styled, Typography } from '@mui/material';

const Root = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(3),
  background: `linear-gradient(36deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  '-webkitBackgroundClip': 'text',
  '-webkitTextFillColor': 'rgba(0, 0, 0, 0)',
  backgroundSize: '200% 100%',
  animation: 'moving 8s ease-in-out infinite',
  userSelect: 'none',
  '@keyframes moving': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '50%': {
      backgroundPosition: '100% 0',
    },
    '100%': {
      backgroundPosition: '0 0',
    },
  },
}));

export const AnimatedTitle: React.FC = (props) => {
  return <Root variant="h2">{props.children}</Root>;
};
