// Material-ui
import { createTheme, responsiveFontSizes } from '@mui/material';

export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: "'Nunito Sans', sans-serif",
      h1: {
        fontFamily: "'Paytone One', sans-serif",
      },
      h2: {
        fontFamily: "'Paytone One', sans-serif",
      },
      h3: {
        fontFamily: "'Paytone One', sans-serif",
      },
      h4: {
        fontFamily: "'Paytone One', sans-serif",
      },
      h5: {
        fontFamily: "'Paytone One', sans-serif",
      },
      h6: {
        fontFamily: "'Paytone One', sans-serif",
      },
      button: {
        fontFamily: "'Paytone One', sans-serif",
        textTransform: 'none',
      },
    },
    palette: {
      primary: {
        main: '#1269D3',
        light: '#D0E3FB',
        dark: '#0A3A75',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#F6D365',
        light: '#FEF8E7',
        dark: '#cfa417',
        contrastText: '#000000',
      },
    },
    shape: {
      borderRadius: 16,
    },
  })
);
