// React
import React, { useEffect } from 'react';

// React router
import { useSearchParams } from 'react-router-dom';

// Material-ui
import { styled, Box, Typography } from '@mui/material';

// Components
import { ExperimentsFieldsList } from '../components/experiments/ExperimentsFieldsList';
import { ExperimentsList } from '../components/experiments/ExperimentsList';

// Visuals
import AstrologyIcon from '../assets/images/znanja-o-svemiru.svg';
import PhysicsIcon from '../assets/images/fizika.svg';
import MathematicsIcon from '../assets/images/matematika.svg';
import ChemistyIcon from '../assets/images/kemija.svg';
import RoboticsIcon from '../assets/images/robotika.svg';
import LeftVisual from '../assets/images/activities-visual-left.svg';
import RightVisual from '../assets/images/activities-visual-right.svg';
import { AnimatedTitle } from '../common/AnimatedTitle';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  minHeight: 'calc(100vh - 95px)',
  [theme.breakpoints.up('lg')]: {
    '&:before': {
      zIndex: -1,
      display: 'block',
      content: '""',
      width: '32.5%',
      height: 833,
      position: 'absolute',
      top: -95,
      left: 0,
      background: `url(${LeftVisual})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'top left',
    },
    '&:after': {
      zIndex: -1,
      content: '""',
      position: 'absolute',
      width: '32.5%',
      height: 654,
      top: -95,
      right: 0,
      background: `url(${RightVisual})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'top right',
    },
  },
}));

const fields = [
  {
    uid: 'robotika',
    icon: RoboticsIcon,
    title: 'Robotika',
    description:
      'Ključne vještine iz područja programiranja i algoritamskog razmišljanja mogu se razvijati u potpunosti i bez upotrebe računala i nema razloga da se radionice za razvoj ovih kompetencija ne provode u bilo kojem okruženju. Na primjer, jedna od aktivnosti koja razvija računalno razmišljanje je precizno praćenje uputa, koja se može vježbati pri izradi kolača (praćenje uputa iz kuharice) ili slaganju LEGO kockica. Ako-onda pravila mogu se vježbati uz pomoć raznih priča (npr. ako je kuća od cigle onda je vuk neće moći otpuhati), algoritmi sortiranja mogu se vježbati na djeci u vrsti (npr. poredak djece po visini, po starosti i sl.), itd.',
    count: 3,
    color: '#A6E8F2',
  },
  {
    uid: 'znanja-o-svemiru',
    icon: AstrologyIcon,
    title: 'Znanja o svemiru',
    description:
      'Astronomija, odnosno zvjezdoznanstvo je egzaktna prirodna znanost koja istražuje svemirska tijela, njihova gibanja, nastanak i razvoj, kao i procese koji se zbivaju na njima te je svojim sadržajem pogodna za dječje istraživanje. Ipak, shvaćajući kompleksnost   sadržaja valja poštovati metodičke zakonitosti odgoja i obrazovanja kako bi proces učenja bio motivirajući i prilagođen djetetu predškolske dobi.',
    count: 6,
    color: '#A69FDF',
  },
  {
    uid: 'matematika',
    icon: MathematicsIcon,
    title: 'Matematika',
    description:
      'Djeca matematiku uče primarno vezano uz kontekst. Sadržaji se smisleno povezuju s dječjim svakodnevnim iskustvima te se upravo ta iskustva pretvaraju u temelje na koje će se nadovezivati dublje razumijevanje i izgradnja koncepata. Djeci treba omogućiti da konstruiraju svoje vlastito znanje organizirajući ranije prikupljene i novousvojene informacije. Efekt kojeg ima znanje do kojega je dijete došlo vlastitim istraživanjem („discovery learning“) znatno nadilazi efekte znanja usvojenog poučavanjem. Stoga je uloga odraslog u dječjem istraživačkom upoznavanju s matematičkim konceptima davanje podrške tj. uloga podupiranja dječjeg učenja. ',
    count: 1,
    color: '#EC9693',
  },
  {
    uid: 'kemija',
    icon: ChemistyIcon,
    title: 'Kemija',
    description:
      'Kemija se događa u svijetu oko vas, ne samo u laboratoriju. Materija stupa  u interakciju kako bi stvorila nove proizvode kroz proces koji se naziva kemijska  reakcija ili kemijska promjena. Svaki put kad kuhate ili čistite, to je kemija na djelu . Vaše tijelo živi i raste zahvaljujući kemijskim reakcijama. Postoje reakcije kada uzimate lijekove, palite šibicu i udahnete. Ovi primjeri kemijskih reakcija iz svakodnevnog života mali su uzorak stotina tisuća reakcija koje doživite tijekom dana. Kemijske reakcije česte su u svakodnevnom životu, ali ih možda nećete prepoznati. Kemijske reakcije često uključuju promjene boje, promjene temperature, proizvodnju plina ili stvaranje taloga. ',
    count: 4,
    color: '#F5CC89',
  },
  {
    uid: 'fizika',
    icon: PhysicsIcon,
    title: 'Fizika',
    description:
      'Djeci je važno ostaviti vremena aktivnosti i treba dopustiti da „pogriješe“ jer je to upravo dobra prilika za učenje. Kod djece treba razviti pozitivan stav prema tim „pogreškama“ i na primjeru im pokazati kako je to put do razumijevanja. I znanstvenici ne dolaze odmah do točnih odgovora/modela u svojim istraživanjima. ',
    count: 7,
    color: '#9CE2B8',
  },
];

export const Experiments: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const field = searchParams.get('podrucje');

  useEffect(() => {
    !field && setSearchParams({ podrucje: 'matematika' });
  }, [field]);

  if (!field) return null;

  return (
    <Root>
      <AnimatedTitle>Pokusi</AnimatedTitle>
      <ExperimentsFieldsList activeField={field} fields={fields} />
      <ExperimentsList />
    </Root>
  );
};
