// React
import React from 'react';

// Material-ui
import { styled, Link } from '@mui/material';

const Root = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  fontFamily: '"Paytone One", sans-serif',
  transition: 'all .25s ease-in-out',
  color: theme.palette.primary.dark,
  '& > svg': {
    width: 32,
    height: 'auto',
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

interface IProps {
  label: string;
  icon: JSX.Element;
  url: string;
}

export const SocialLink: React.FC<IProps> = (props) => {
  return (
    <Root target="_blank" href={props.url}>
      {props.icon}
      {props.label}
    </Root>
  );
};
