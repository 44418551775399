// React
import React from 'react';

// Material-ui
import { styled, Box, Typography } from '@mui/material';

// Visuals
import LeftVisual from '../assets/images/activities-visual-left.svg';
import RightVisual from '../assets/images/activities-visual-right.svg';
import WavesVisual from '../assets/images/waves-visual.svg';

// Components
import { ActivitiesList } from '../components/activities/ActivitiesList';
import { AnimatedTitle } from '../common/AnimatedTitle';

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  minHeight: 'calc(100vh - 95px)',
  [theme.breakpoints.up('lg')]: {
    '&:before': {
      zIndex: -1,
      display: 'block',
      content: '""',
      width: '32.5%',
      height: 833,
      position: 'absolute',
      top: -95,
      left: 0,
      background: `url(${LeftVisual})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'top left',
    },
    '&:after': {
      zIndex: -1,
      content: '""',
      position: 'absolute',
      width: '32.5%',
      height: 654,
      top: -95,
      right: 0,
      background: `url(${RightVisual})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'top right',
    },
  },
}));

const Background = styled(Box)(({ theme }) => ({
  zIndex: -1,
  position: 'absolute',
  height: '60%',
  width: '100%',
  background: theme.palette.primary.light,
  '&:before': {
    display: 'block',
    content: '""',
    width: '100%',
    height: 117,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    background: `url(${WavesVisual})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transform: 'translateY(-99%)',
  },
}));

export const Activities: React.FC = () => {
  return (
    <Root>
      <AnimatedTitle>Aktivnosti</AnimatedTitle>
      <ActivitiesList />
      <Background />
    </Root>
  );
};
