// React router
import { NavLink } from 'react-router-dom';

// Material-ui
import { styled } from '@mui/material';

export const NavigationLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.h6.fontFamily,
  fontWeight: theme.typography.h6.fontWeight,
  fontSize: theme.typography.h6.fontSize,
  lineHeight: theme.typography.h6.lineHeight,
  letterSpacing: theme.typography.h6.letterSpacing,
  textDecoration: 'none',
  transition: 'all .25s ease-in-out',
  '&.active, &:hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.up('lg')]: {
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    letterSpacing: theme.typography.h4.letterSpacing,
  },
}));
