// React
import React, { useState, useEffect } from 'react';

// React router
import { useLocation } from 'react-router';

// Material-ui
import { styled, Grid as MuiGrid } from '@mui/material';

// Common
import { FooterLogo } from './FooterLogo';

// Common
import { WavesDecoration } from '../../common/decorations/WavesDecoration';

// Components
import { Navigation } from '../Navigation';
import { Socials } from '../socials/Socials';

import WavesVisual from '../../assets/images/waves-visual.svg';

const Root = styled(MuiGrid)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  background: theme.palette.primary.light,
  margin: theme.spacing('117px', 0, 0),
  '&:before': {
    display: 'block',
    content: '""',
    width: '100%',
    height: 117,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    background: `url(${WavesVisual})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transform: 'translateY(-99%)',
  },
  '&.alternate-footer': {
    margin: 0,
    '&:before': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& > div:not(:last-child)': {
      margin: theme.spacing(0, 0, 2),
    },
  },
})) as typeof MuiGrid;

const Grid = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));

export const Footer: React.FC = () => {
  const [alternateFooter, setAlternateFooter] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const exeptionRoutes = ['/pokusi', '/aktivnosti'];
    const regex = new RegExp('[^/]*/([^/]*)');

    if (exeptionRoutes.find((r) => r === pathname.match(regex)![0])) {
      setAlternateFooter(true);
    } else {
      setAlternateFooter(false);
    }
  }, [pathname]);

  return (
    <Root
      container
      className={alternateFooter ? 'alternate-footer' : ''}
      component="footer"
    >
      <Grid item xs={12} md={3}>
        <Socials />
      </Grid>
      <Grid item xs={12} md={6}>
        <Navigation />
      </Grid>
      <Grid item xs={12} md={3}>
        <FooterLogo />
      </Grid>
    </Root>
  );
};
