// React
import React from 'react';

// Material-ui
import { styled, Box } from '@mui/material';

// Visual
import EsfTape from '../../assets/images/esf-lenta.png';

const Root = styled(Box)(({ theme }) => ({
  '& img': {
    width: '100%',
    height: 'auto',
  },
}));

export const FooterLogo: React.FC = () => {
  return (
    <Root>
      <img alt="ESF lenta" src={EsfTape} />
    </Root>
  );
};
