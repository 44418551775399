// React
import React, { useState, useEffect } from 'react';

// React router
import { useLocation } from 'react-router-dom';

// Material-ui
import { styled, Box, IconButton, Drawer as MuiDrawer } from '@mui/material';

// Components
import { MenuHeader } from './MenuHeader';
import { MenuNavigation } from './MenuNavigation';
import { MenuFooter } from './MenuFooter';
import { MenuIcon } from '../../../assets/icons/MenuIcon';

// Visuals
import CornerWaves from '../../../assets/images/corner-waves-visual.svg';

const Root = styled(Box)(({ theme }) => ({}));

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    position: 'relative',
    minWidth: '60%',
    maxWidth: '90%',
    '&:before': {
      content: '""',
      width: '100%',
      height: '25%',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      background: `url(${CornerWaves})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    '&:after': {
      content: '""',
      width: '100%',
      height: '25%',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      background: `url(${CornerWaves})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      transform: 'scaleY(-1) scaleX(-1)',
    },
  },
}));

export const Menu: React.FC = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleClose();
  }, [pathname]);

  return (
    <React.Fragment>
      <IconButton onClick={handleOpen}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        <MenuHeader />
        <MenuNavigation />
        <MenuFooter />
      </Drawer>
    </React.Fragment>
  );
};
