// React
import React from 'react';

// Material-ui
import { styled, Box, Typography } from '@mui/material';

// Visuals
import WavesVisual from '../../assets/images/waves-visual.svg';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: theme.spacing(3),
  flexGrow: 1,
  width: '100%',
  margin: theme.spacing('117px', 0, 0),
  background: theme.palette.primary.light,
  '&:before': {
    display: 'block',
    content: '""',
    width: '100%',
    height: 117,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    background: `url(${WavesVisual})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    transform: 'translateY(-99%)',
  },
}));

export const ExperimentsList: React.FC = () => {
  return (
    <Root>
      <Typography variant="h2" color="textSecondary">
        Pokusi dolaze uskoro...
      </Typography>
    </Root>
  );
};
