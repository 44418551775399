// React
import React from 'react';

// Material-ui
import { styled, Box } from '@mui/material';
import {
  Instagram as InstagramIcon,
  FacebookRounded as FacebookIcon,
} from '@mui/icons-material';

// Common
import { SocialLink } from './SocialLink';

// Icons

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& > a:not(:last-child)': {
    margin: theme.spacing(0, 3, 0, 0),
  },
}));

export const Socials: React.FC = () => {
  return (
    <Root>
      <SocialLink
        label="Instagram"
        icon={<InstagramIcon />}
        url="https://www.instagram.com/"
      />
      <SocialLink
        label="Facebook"
        icon={<FacebookIcon />}
        url="https://www.facebook.com/"
      />
    </Root>
  );
};
