// React
import React from 'react';
import clsx from 'clsx';

// Material-ui
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';

// Types
import { IActivity } from '../../ts';

// Components
import { ActivityTitle } from './ActivityTitle';
import { ActivityContent } from './ActivityContent';

const Root = styled(Box)(({ theme }) => ({
  '&.alternate-activity': {
    '& h6': {
      transform: 'translateX(calc(-100% - 64px))',
    },
    '& p': {
      transform: 'translateX(calc(-100% - 64px))',
    },
    '& button': {
      transform: 'translateX(calc(-100% - 64px))',
    },
  },
}));

interface IProps extends IActivity {}

export const Activity: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  const alternateClass = clsx(
    props.alternate && desktop && 'alternate-activity'
  );

  return (
    <Root className={alternateClass}>
      <ActivityTitle title={props.title} />
      <ActivityContent text={props.text} images={props.images} />
    </Root>
  );
};
