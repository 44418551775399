// React
import React from 'react';
import { Section } from '../components/Section';

// React router
import { Link } from 'react-router-dom';

// Material-ui
import { styled, Box, Button } from '@mui/material';

// Common
import { HomepageIntroDecoration } from '../common/decorations/HomepageIntroDecoration';

// Components
import { HomepageRecentExperiments } from '../components/homepage/HomepageRecentExperiments';
import { HomepagePartners } from '../components/homepage/HomepagePartners';

// Visuals
import IntroVisual from '../assets/images/intro-visual.svg';
import ActivitiesVisual from '../assets/images/activities-visual.svg';
import ExperimentsVisual from '../assets/images/experiments-visual.svg';

export const Homepage: React.FC = () => {
  return (
    <React.Fragment>
      <Section
        uid="intro-section"
        title="Istraživanjem do znanja"
        text={[
          'Istraživači su danas definitivno postigli konsenzus i smatraju da djeca nisu samo pasivni promatrači već aktivni dionici u postupcima usvajanja znanja. Mnoga istraživanja iz područja psihologije i pedagogije ističu da su djeca rođeni znanstvenici jer su znatiželja i potreba za istraživanjem ono što ih karakterizira.',
          'Ovaj projekt je od značajne važnosti jer će se tijekom projekta pri osmišljavanju projektnih aktivnosti pristupati interdisciplinarno. Projekt okuplja stručnjake iz STEM-a i područja odgoja i obrazovanja koji će osigurati približavanje STEM sadržaja odgajateljima, roditeljima i svim dobnim skupinama vrtićke djece.',
        ]}
        visual={IntroVisual}
      />
      <Section
        uid="activities-section"
        title="Aktivnosti"
        text={[
          'Mjesto na kojem su objavljene sve prošle i nadolazeće aktivnosti vezane uz projekt. Svaka aktivnost je kronološki prikazana na lenti vremena.',
          'Aktivnosti mogu imati priložene materijale i/ili galerije slika kojima možete pristupiti klikom na za to predviđen gumb.',
        ]}
        visual={ActivitiesVisual}
        inverse={true}
        action={
          <Button
            component={React.forwardRef((props, ref) => (
              <Link {...props} to="aktivnosti" />
            ))}
            color="secondary"
            variant="contained"
          >
            Pogledajte aktivnosti
          </Button>
        }
      />
      <Section
        uid="experiments-section"
        title="Pokusi"
        text={[
          'Mjesto gdje će biti objavljeni pokusi iz: Matematike, Robotike, Znanja o svemiru, Kemije i Fizike. Pokuse možete filtrirati po željenom području ili istraživati dok su prikazani svi odjednom.',
          'Svaki pokus sastoji se od tekstualnog i video zapisa. Upute su tekstualnog zapisa kako bi vam uz video zapis omogućilo lakše praćenje pokusa i/ili dodatno objasne pojedine korake ako je potrebno.',
        ]}
        visual={ExperimentsVisual}
        action={
          <Button
            component={React.forwardRef((props, ref) => (
              <Link {...props} to="pokusi" />
            ))}
            color="secondary"
            variant="contained"
          >
            Pogledajte pokuse
          </Button>
        }
      />
      <HomepageRecentExperiments />
      <HomepagePartners />
    </React.Fragment>
  );
};
