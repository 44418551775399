// React
import React from 'react';

// Material-ui
import { styled, Box, IconButton } from '@mui/material';
import { LogoLink } from '../../../common/LogoLink';
import { Close } from '@mui/icons-material';

const Root = styled(Box)(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  height: '25%',
  '& a': {
    margin: theme.spacing(0, 3, 0, 0),
  },
}));

export const MenuHeader: React.FC = () => {
  return (
    <Root>
      <LogoLink />
      <IconButton>
        <Close />
      </IconButton>
    </Root>
  );
};
