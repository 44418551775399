// React
import React from 'react';

// Material-ui
import { styled, Box } from '@mui/material';

// Common
import { NavigationLink } from '../../../common/NavigationLink';

const Root = styled(Box)(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50%',
  '& a': {
    fontSize: theme.typography.h3.fontSize,
  },
  '& a:not(:last-child)': {
    margin: theme.spacing(0, 0, 2),
  },
}));

export const MenuNavigation: React.FC = () => {
  return (
    <Root>
      <NavigationLink to="/">Naslovna</NavigationLink>
      <NavigationLink to="/aktivnosti">Aktivnosti</NavigationLink>
      <NavigationLink to="/pokusi">Pokusi</NavigationLink>
      <NavigationLink to="/o-projektu">O Projektu</NavigationLink>
    </Root>
  );
};
