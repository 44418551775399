// React
import React from 'react';
import ReactDOM from 'react-dom';

// React router
import { BrowserRouter } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';

// Material-ui
import { ThemeProvider, CssBaseline } from '@mui/material';

// Styles
import './index.css';

// App
import { App } from './App';

// Config
import { store } from './config/store';
import { theme } from './config/theme';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
