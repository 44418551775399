// React
import React from 'react';

// Material-ui
import { styled, Box, useTheme, useMediaQuery } from '@mui/material';

// Components
import { Activity } from './Activity';

// Visual
import KTZTCoverBack from '../../assets/images/ktzt-cover-back.jpg';
import KTZTCoverFront from '../../assets/images/ktzt-cover-front.jpg';

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  '& > div:not(:last-child)': {
    margin: theme.spacing(0, 0, 2),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3, 0),
    transform: 'translateX(calc(50% - 16px))',
  },
}));

const activities = [
  {
    uid: 'izrada-kurikuluma-za-program-trening-za-trenere',
    title: 'Izrada kurikuluma za program "Trening za trenere"',
    text: 'Stručnjaci OMEP-a i stručnjaci organizacija partnera izradit će kurikulum za program trening-za-trenere u kojem će sudjelovati interdisciplinarni stručnjaci iz područja odgoja i obrazovanja te stručnjaci iz područja STEM-a. Trening će se provoditi putem radionica koje će biti organizirane kao moduli a biti će namijenjen stručnjacima koji rade s djecom u području STEM-a.',
    images: [KTZTCoverFront, KTZTCoverBack],
  },
  {
    uid: 'izrada-kurikuluma-i-radionica-za-djecu-mlade-opcu-populaciju',
    title: 'Izrada kurikuluma i radionica za djecu/mlade/opću populaciju',
    text: 'Stručnjaci uključeni u projektni tim izradit će kurikulum za edukacije za djecu koje će se provoditi iz područja: Robotike, Znanja o svemiru, Matematike, Kemije I Fizike. Svrha programa koji se razvija je da predstavlja inovativni kurikulum usmjereni na stjecanje kompetencija iz STEM područja na razini predškolskog odgoja i obrazovanja.',
  },
  {
    uid: 'strucna-konferencija',
    title: 'Stručna konferencija',
    text: 'Stručna konferencija će biti posvećena središnjoj temi projekta (Popularizacija STEM-a u predškolskom odgoju i obrazovanju), a na istoj će sudjelovati stručnjaci iz područja STEM-a i stručnjaci iz područja obrazovanja. U prvom djelu konferencije planira se održavanje stručnih panela, a u drugom dijelu pokazne vježbe za krajnje korisnike i za same sudionike, a kako bi vidjeli praktične primjere rada s djecom, roditeljima i mladima.',
  },
  {
    uid: 'sajam-znanosti',
    title: 'Sajam znanosti',
    text: 'Tijekom sajma znanosti stručnjaci će sudjelovati u raspravi u okviru okruglog stola, a paralelno će se održavati sajamski program iz područja znanosti kako bi se široj javnosti i krajnjim korisnicima dodatno približio STEM. Planiraju se pokusi, kvizovi i natjecanja za djecu, ali i za opću populaciju.',
  },
  {
    uid: 'znanstveni-piknici',
    title: 'Znanstveni piknici',
    text: 'Znanstveni piknici biti će tematski podijeljeni u područja: Robotika, Znanja o svemiru, Matematika, Kemija, Fizika. Tijekom piknika će se za djecu i mlade uključene u projektne aktivnosti osigurati pokazne vježbe i pokusi te razgovor sa znanstvenicima iz pojedinog područja.',
  },
];

export const ActivitiesList: React.FC = () => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Root maxWidth="sm">
      {activities.map((activity, index) => (
        <Activity
          key={activity.uid}
          alternate={index % 2 !== 0}
          uid={activity.uid}
          title={activity.title}
          text={activity.text}
          images={activity.images}
        />
      ))}
    </Root>
  );
};

// <Activity>
//   <ActivityTitle title="Početak vremenske crte" />
// </Activity>
