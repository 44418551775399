// React
import React from 'react';

// Material-ui
import { styled, Box } from '@mui/material';

// Common
import { NavigationLink } from '../common/NavigationLink';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '& > a:not(:last-child)': {
    margin: theme.spacing(0, 0, 1),
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    '& > a:not(:last-child)': {
      margin: theme.spacing(0, 3, 0, 0),
    },
  },
}));

export const Navigation: React.FC = () => {
  return (
    <Root component="nav">
      <NavigationLink to="/">Naslovna</NavigationLink>
      <NavigationLink to="/aktivnosti">Aktivnosti</NavigationLink>
      <NavigationLink to="/pokusi">Pokusi</NavigationLink>
      <NavigationLink to="/o-projektu">O projektu</NavigationLink>
    </Root>
  );
};
