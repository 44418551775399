// React
import React from 'react';

// Material-ui
import { styled, useTheme, useMediaQuery, Box, Grid } from '@mui/material';

// Components
import { Carousel } from '../Carousel';

// Visuals
import DVDDLogo from '../../assets/images/dobro-drvo-logo.png';
import OMEPLogo from '../../assets/images/omep-logo.png';
import FERLogo from '../../assets/images/fer-logo.png';
import NZRCDLogo from '../../assets/images/nzrcd-logo.png';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  '& h4:first-letter': {
    color: theme.palette.secondary.main,
  },
}));

const CarouselLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  height: '100%',
  minHeight: '100%',
  '& img': {
    width: '80%',
    height: 'auto',
  },
}));

const Logo = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& img': {
    width: '100%',
    height: 'auto',
  },
}));

const logos = [
  {
    uid: 'dobro-drvo-logo',
    name: 'Dječji vrtić Dobro Drvo',
    alt: 'Dječji vrtić Dobro Drbo logo',
    src: DVDDLogo,
  },
  {
    uid: 'omep-logo',
    name: 'OMEP Hrvatska',
    alt: 'OMEP Hrvatska logo',
    src: OMEPLogo,
  },
  {
    uid: 'fer-logo',
    name: 'Fakultet elektrotehnike i računarstva',
    alt: 'Fer logo',
    src: FERLogo,
  },
  {
    uid: 'nzrcd-logo',
    name: 'Nacionalna zaklada za razvoj civilnog društva',
    alt: 'NZRCD logo',
    src: NZRCDLogo,
  },
];

export const HomepagePartners: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  let content;
  if (mobile) {
    content = (
      <Carousel
        items={logos.map((logo) => (
          <CarouselLogo key={logo.uid}>
            <img alt={logo.alt} src={logo.src} />
          </CarouselLogo>
        ))}
      />
    );
  } else {
    content = (
      <Grid
        sx={{ p: 3 }}
        container
        alignItems="center"
        spacing={3}
        maxWidth="lg"
      >
        {logos.map((logo) => (
          <Logo key={logo.uid} item xs={3}>
            <img alt={logo.alt} src={logo.src} />
          </Logo>
        ))}
      </Grid>
    );
  }

  return <Root>{content}</Root>;
};
