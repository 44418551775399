// React
import React from 'react';

// Material-ui
import { styled, Box, Typography, Grid } from '@mui/material';

// Types
import { IActivity } from '../../ts';
import { ExpendableImage } from '../ExpendableImage';

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 0, 0, '32px'),
  margin: theme.spacing(0, 0, 0, '14px'),
  '& p': {
    padding: theme.spacing(2),
    background: '#ffffff',
    borderRadius: theme.spacing(2),
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
  },
  '&:before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: theme.spacing(0.5),
    borderRadius: theme.spacing(4),
    background: theme.palette.primary.dark,
  },
}));

const Images = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const Image = styled(Grid)(({ theme }) => ({
  '& > img': {
    height: 'auto',
    width: '100%',
    borderRadius: theme.spacing(2),
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
  },
}));

interface IProps extends Pick<IActivity, 'text' | 'images'> {}

export const ActivityContent: React.FC<IProps> = (props) => {
  return (
    <Root>
      <Typography>{props.text}</Typography>
      {props.images && (
        <Images spacing={2} container>
          {props.images.map((image, index) => (
            <Grid item xs={6} key={'img-' + index}>
              <ExpendableImage image={image} />
            </Grid>
          ))}
        </Images>
      )}
    </Root>
  );
};
