// React
import React from 'react';

// React router
import { Link } from 'react-router-dom';

// Material-ui
import { styled } from '@mui/material';

// Visuals
import Logo from '../assets/images/istrazivanjem-do-znanja-logo.svg';

const Root = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down("sm")]: {
    '& > img': {
      height: '48px',
      width: 'auto',
    }
  }
}));

export const LogoLink: React.FC = () => {
  return (
    <Root to="/">
      <img alt="Istrazivanjem do znanja logo" src={Logo} />
    </Root>
  );
};
