// React
import React from 'react';

// Material-ui
import {
  styled,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  Grid as MuiGrid,
} from '@mui/material';
import {
  ArrowBackIosNewRounded as PrevIcon,
  ArrowForwardIosRounded as NextIcon,
} from '@mui/icons-material';

// Types
import { IField } from '../../ts';

const Root = styled(MuiGrid)(({ theme }) => ({
  margin: theme.spacing(0, 'auto'),
  padding: theme.spacing(3),
}));

const Grid = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Fields = styled(MuiGrid)(({ theme }) => ({}));

const FieldIcon = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > img': {
    width: '100%',
    height: '100%',
    filter: 'grayscale(0)',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
    borderRadius: '100%',
    transition: 'all .25s ease-in-out',
  },
  '&.grayscale': {
    '& > img': {
      boxShadow: 'none',
      filter: 'grayscale(1)',
    },
  },
}));

// const FieldIcon = styled(Box)(({ theme }) => ({
//   boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
//   borderRadius: '100%',
//   '& > img': {
//     width: '100%',
//     height: '100%',
//     filter: 'grayscale(0)',
//     transition: 'all .25s ease-in-out',
//   },
//   '&.grayscale': {
//     boxShadow: 'none',
//     '& > img': {
//       filter: 'grayscale(1)',
//     },
//   },
// }));

const Button = styled(IconButton)(({ theme }) => ({
  boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
  transition: 'all .25s ease-in-out',
  border: '2px solid rgba(0, 0, 0, 0)',
  '&:hover': {
    border: '2px solid rgba(0, 0, 0, .36)',
  },
  '&.Mui-disabled': {
    display: 'none',
  },
}));

interface IProps {
  activeFieldIndex: number;
  activeField: IField['uid'];
  fields: IField[];
  onPrevClick: () => void;
  onNextClick: () => void;
}

export const ExperimentsFieldsNavigation: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const fieldsCount = props.fields.length;

  const prevField = props.fields[props.activeFieldIndex - 1];
  const nextField = props.fields[props.activeFieldIndex + 1];

  return (
    <Root maxWidth="sm" container>
      <Grid item xs={2}>
        {props.activeFieldIndex !== 0 && (
          <Button
            sx={{
              mr: 2,
              background: prevField.color,
              '&:hover': {
                background: prevField.color,
              },
            }}
            onClick={props.onPrevClick}
          >
            <PrevIcon />
          </Button>
        )}
      </Grid>
      <Grid sx={{ p: mobile ? 1 : 2 }} item xs={8}>
        <Fields spacing={mobile ? 1 : 2} container>
          {props.fields.map((field) => (
            <FieldIcon
              item
              xs={2.4}
              key={`navigation-icon-${field.uid}`}
              className={props.activeField !== field.uid ? 'grayscale' : ''}
            >
              <img alt="" src={field.icon} />
            </FieldIcon>
          ))}
        </Fields>
      </Grid>
      <Grid item xs={2}>
        {props.activeFieldIndex !== props.fields.length - 1 && (
          <Button
            sx={{
              background: props.fields[props.activeFieldIndex + 1].color,
              '&:hover': {
                background: props.fields[props.activeFieldIndex + 1].color,
              },
            }}
            onClick={props.onNextClick}
          >
            <NextIcon />
          </Button>
        )}
      </Grid>
    </Root>
  );
};
