// React
import React, { useState } from 'react';

// React router
import { useSearchParams } from 'react-router-dom';

// Material-ui
import {
  styled,
  useTheme,
  useMediaQuery,
  Grid as MuiGrid,
  IconButton,
} from '@mui/material';

// Types
import { IField } from '../../ts';
import { ExperimentField } from './ExperimentField';
import { ExperimentsFieldsNavigation } from './ExperimentsFieldsNavigation';

const Root = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('lg')]: {
    minHeight: '60vh',
  },
}));

const Grid = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Button = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
  transition: 'all .25s ease-in-out',
  border: '2px solid rgba(0, 0, 0, 0)',
  '&:hover': {
    border: '2px solid rgba(0, 0, 0, .36)',
  },
  '&.Mui-disabled': {
    display: 'none',
  },
}));

interface IProps {
  activeField: IField['uid'];
  fields: IField[];
}

export const ExperimentsFieldsList: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [searchParams, setSearchParams] = useSearchParams();

  const [activeFieldIndex, setActiveFieldIndex] = useState(() =>
    props.fields.map((field) => field.uid).indexOf(props.activeField)
  );

  const handleNext = () => {
    const next = props.fields[activeFieldIndex + 1];
    setSearchParams({ podrucje: next.uid });
    setActiveFieldIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrev = () => {
    const prev = props.fields[activeFieldIndex - 1];
    setSearchParams({ podrucje: prev.uid });
    setActiveFieldIndex((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Root>
      <MuiGrid container>
        <Grid item xs={3}>
          {props.fields[activeFieldIndex - 1] && desktop && (
            <ExperimentField
              title={props.fields[activeFieldIndex - 1].title}
              description={
                props.fields[activeFieldIndex].description.substr(0, 256) +
                '...'
              }
              icon={props.fields[activeFieldIndex - 1].icon}
              count={props.fields[activeFieldIndex - 1].count}
              color={props.fields[activeFieldIndex - 1].color}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <ExperimentField
            title={props.fields[activeFieldIndex].title}
            description={props.fields[activeFieldIndex].description}
            icon={props.fields[activeFieldIndex].icon}
            count={props.fields[activeFieldIndex].count}
            color={props.fields[activeFieldIndex].color}
            active={true}
          />
        </Grid>
        <Grid item xs={3}>
          {props.fields[activeFieldIndex + 1] && desktop && (
            <ExperimentField
              title={props.fields[activeFieldIndex + 1].title}
              description={
                props.fields[activeFieldIndex].description.substr(0, 256) +
                '...'
              }
              icon={props.fields[activeFieldIndex + 1].icon}
              count={props.fields[activeFieldIndex + 1].count}
              color={props.fields[activeFieldIndex + 1].color}
            />
          )}
        </Grid>
      </MuiGrid>
      <ExperimentsFieldsNavigation
        activeFieldIndex={activeFieldIndex}
        activeField={props.activeField}
        fields={props.fields}
        onPrevClick={handlePrev}
        onNextClick={handleNext}
      />
    </Root>
  );
};
