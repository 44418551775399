// React
import React, { useRef, useEffect } from 'react';

// Material-ui
import {
  styled,
  useTheme,
  useMediaQuery,
  Box,
  Grid as MuiGrid,
} from '@mui/material';

// Common
import { LogoLink } from '../../common/LogoLink';

// Components
import { Navigation } from '../Navigation';
import { Menu } from './menu/Menu';

// Visuals
import EmblemEU from '../../assets/images/eu-emblem.svg';

const Root = styled(MuiGrid)(({ theme }) => ({
  zIndex: 100,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  padding: theme.spacing(2),
  transition: 'all .25s ease-in-out',
  '&.active-header': {
    background: '#FFFFFF',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, .125)',
  },
})) as typeof MuiGrid;

const Grid = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
}));

const LogoEU = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 3, 0, 0),
  '& img': {
    height: '86px',
    width: 'auto',
  },
  [theme.breakpoints.down("sm")]: {
    '& > img': {
      height: '64px',
    }
  }
}));

export const Header: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.pageXOffset > 0 && element.current!.classList.add('active-header');

    const listener = () => {
      if (window.pageYOffset > 0) {
        element.current!.classList.add('active-header');
      } else {
        element.current!.classList.remove('active-header');
      }
    };

    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, [element]);

  return (
    <Root ref={element} container component="header">
      <Grid item xs={6}>
        <LogoEU>
          <img alt="EU emblem" src={EmblemEU} />
        </LogoEU>
        <LogoLink />
      </Grid>
      <Grid item xs={6} sx={{ justifyContent: 'flex-end' }}>
        {mobile ? <Menu /> : <Navigation />}
      </Grid>
    </Root>
  );
};


