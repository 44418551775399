// React
import React from 'react';

export const MenuIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_527_2022)">
        <path
          d="M5 0H1C0.448 0 0 0.448 0 1V5C0 5.552 0.448 6 1 6H5C5.552 6 6 5.552 6 5V1C6 0.448 5.552 0 5 0Z"
          fill="#1269D3"
        />
        <path
          d="M5 9H1C0.448 9 0 9.448 0 10V14C0 14.552 0.448 15 1 15H5C5.552 15 6 14.552 6 14V10C6 9.448 5.552 9 5 9Z"
          fill="#0A3A75"
        />
        <path
          d="M5 18H1C0.448 18 0 18.448 0 19V23C0 23.552 0.448 24 1 24H5C5.552 24 6 23.552 6 23V19C6 18.448 5.552 18 5 18Z"
          fill="#1269D3"
        />
        <path
          d="M14 0H10C9.448 0 9 0.448 9 1V5C9 5.552 9.448 6 10 6H14C14.552 6 15 5.552 15 5V1C15 0.448 14.552 0 14 0Z"
          fill="#0A3A75"
        />
        <path
          d="M14 9H10C9.448 9 9 9.448 9 10V14C9 14.552 9.448 15 10 15H14C14.552 15 15 14.552 15 14V10C15 9.448 14.552 9 14 9Z"
          fill="#1269D3"
        />
        <path
          d="M14 18H10C9.448 18 9 18.448 9 19V23C9 23.552 9.448 24 10 24H14C14.552 24 15 23.552 15 23V19C15 18.448 14.552 18 14 18Z"
          fill="#0A3A75"
        />
        <path
          d="M23 0H19C18.448 0 18 0.448 18 1V5C18 5.552 18.448 6 19 6H23C23.552 6 24 5.552 24 5V1C24 0.448 23.552 0 23 0Z"
          fill="#1269D3"
        />
        <path
          d="M23 9H19C18.448 9 18 9.448 18 10V14C18 14.552 18.448 15 19 15H23C23.552 15 24 14.552 24 14V10C24 9.448 23.552 9 23 9Z"
          fill="#0A3A75"
        />
        <path
          d="M23 18H19C18.448 18 18 18.448 18 19V23C18 23.552 18.448 24 19 24H23C23.552 24 24 23.552 24 23V19C24 18.448 23.552 18 23 18Z"
          fill="#1269D3"
        />
      </g>
      <defs>
        <clipPath id="clip0_527_2022">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
