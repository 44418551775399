// React
import React from 'react';

// React router
import { Outlet } from 'react-router-dom';

// Material-ui
import { Box } from '@mui/material';

// Components
import { Header } from '../components/header/Header';
import { Footer } from '../components/footer/Footer';
import { CookiesBanner } from '../components/CookiesBanner';

export const Layout: React.FC = () => {
  return (
    <React.Fragment>
      <Header />
      <Box sx={{ mt: '95px' }} component="main">
        <Outlet />
        <CookiesBanner />
      </Box>
      <Footer />
    </React.Fragment>
  );
};
